<template>
  <view-item title="评价排名">
    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active" @change="tabs.change">
            <en-tab-pane label="消费评价" name="estimate">
              <en-table
                :data="estimate.data"
                :height="height - 55"
                :loading="estimate.loading"
                :method="estimate.get"
                pagination
                :paging="estimate.paging"
              >
                <en-table-column label="操作">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    <en-button text @click="operation.detail.click(row)"> 查看</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="客户名称">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    <en-button text @click="operation.name.click(row)"> {{ row.service?.customer?.name }}</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="消费日期">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    {{ formatDate(row.service?.preparedDatetime) }}
                  </template>
                </en-table-column>
                <en-table-column label="消费金额" prop="service.receivedAmount"></en-table-column>
                <en-table-column label="评价内容" prop="comment"></en-table-column>
                <en-table-column label="整体评价">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    <en-rate :model-value="row.advisorLevel" disabled></en-rate>
                  </template>
                </en-table-column>
                <en-table-column label="晒图">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    <en-button v-if="row.imgUrls.length > 0" @click="operation.img.click(row)" type="primary" text>查看</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="车牌号" prop="service.vehicle.plateNo"></en-table-column>
                <en-table-column label="已回复" prop="status.message"></en-table-column>
                <en-table-column label="开放显示" prop="open.message"></en-table-column>
                <en-table-column label="评价时间" prop="preparedDatetime">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    {{ formatDate(row.preparedDatetime, true) }}
                  </template>
                </en-table-column>
              </en-table>
            </en-tab-pane>
            <en-tab-pane label="员工排名" name="rank" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <en-form label-position="left">
                <en-form-item label="分类">
                  <en-checkbox v-model="form.data.type" true-label="all" @change="form.type.change">全部员工</en-checkbox>
                  <en-checkbox v-model="form.data.type" true-label="work" @change="form.type.change">技师</en-checkbox>
                  <en-checkbox v-model="form.data.type" true-label="advisor" @change="form.type.change">服务顾问</en-checkbox>
                </en-form-item>
              </en-form>

              <flex-box>
                <template #default="{ height }">
                  <en-table :data="rank.data" :height="height" :loading="rank.loading">
                    <en-table-column label="姓名" prop="userName"></en-table-column>
                    <en-table-column label="一星" prop="oneCount"></en-table-column>
                    <en-table-column label="二星" prop="twoCount"></en-table-column>
                    <en-table-column label="三星" prop="threeCount"></en-table-column>
                    <en-table-column label="四星" prop="fourCount"></en-table-column>
                    <en-table-column label="五星" prop="fiveCount"></en-table-column>
                    <en-table-column label="平均" prop="averagePoint"></en-table-column>
                    <en-table-column label="总量" prop="count"></en-table-column>
                    <en-table-column label="评价次数" prop="estimateCount"></en-table-column>
                  </en-table>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
  <en-drawer v-model="detail.visible" title="评价详情">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="车主姓名">
        <en-input v-model="detail.form.data.service.customer.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="车牌号">
        <en-input v-model="detail.form.data.service.vehicle.plateNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="整体评价">
        <en-rate v-model="detail.form.data.advisorLevel" disabled></en-rate>
      </en-form-item>
      <en-form-item label="评价标签">
        <en-input :value="detail.form.data.tags.map((item) => `${item}`).join(',')" disabled></en-input>
      </en-form-item>
      <en-form-item label="评价内容">
        <en-input v-model="detail.form.data.comment" disabled></en-input>
      </en-form-item>
      <en-form-item label="回复内容" v-if="detail.form.data.children.length !== 0">
        <en-input :key="item.id" v-model="item.comment" disabled v-for="item of detail.form.data.children"></en-input>
      </en-form-item>
      <en-form-item label="服务顾问">
        <en-input v-model="detail.form.data.service.advisor.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="工单">
        <en-input v-model="detail.form.data.service.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="评价时间">
        <en-input v-model="detail.form.data.service.preparedDatetime" disabled></en-input>
      </en-form-item>
      <en-form-item label="消费内容">
        <en-input :value="detail.form.data.service.maintenances.map((item) => `${item.name}`).join(',')" disabled></en-input>
      </en-form-item>
      <en-form-item label="消费金额">
        <en-input v-model="detail.form.data.service.receivedAmount" disabled></en-input>
      </en-form-item>
      <en-form-item label="消费日期">
        <en-input v-model="detail.form.data.service.settlementDatetime" disabled></en-input>
      </en-form-item>
      <en-form-item label="是否开放显示">
        <en-radio disabled label="Y" v-model="detail.form.data.open.code">是</en-radio>
        <en-radio disabled label="N" v-model="detail.form.data.open.code">否</en-radio>
      </en-form-item>
    </en-form>
  </en-drawer>
  <en-dialog v-model="amount.visible" title="工单详情">
    <img :src="amount.imgSrc" :zoom-rate="1.2" :initial-index="4" fit="cover" style="width: 700px; height: 700px" />
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          name: {
            click(row: EnocloudServiceDefinitions['ServiceEstimateDto']) {
              this.detail.form.data.id = row.id
              this.detail.form.get()
              this.detail.visible = true
            }
          },
          detail: {
            async click(row: EnocloudServiceDefinitions['ServiceEstimateDto']) {
              this.detail.form.data.id = row.id
              await this.detail.form.get()
              this.detail.visible = true
            }
          },
          img: {
            click(row: EnocloudServiceDefinitions['ServiceEstimateDto']) {
              this.amount.imgSrc = row.imgUrls
              this.amount.visible = true
            }
          }
        }
      },
      amount: {
        visible: false
      },
      tabs: {
        active: 'estimate',
        change() {
          switch (this.tabs.active) {
            case 'estimate':
              this.estimate.get()
              break
            case 'rank':
              this.form.type.change()
              break
          }
        }
      },
      form: {
        data: {
          type: 'all'
        },
        children: {
          type: {
            change() {
              switch (this.form.data.type) {
                case 'all':
                  this.rank.all()
                  break
                case 'work':
                  this.rank.work()
                  break
                case 'advisor':
                  this.rank.advisor()
                  break
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              service: {
                customer: {},
                vehicle: {},
                advisor: {},
                maintenances: []
              },
              open: {},
              tags: [],
              children: []
            },
            ajax: {
              get: {
                action: 'GET /enocloud/service/estimate/:estimateId',
                data: 'object',
                loading: true,
                params(params) {
                  ;(params.paths = [this.detail.form.data.id]), (params.payload = this.detail.form.data)
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            }
          }
        }
      },
      estimate: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/estimate',
            data: 'array',
            loading: true,
            pagination: true
          }
        }
      },
      rank: {
        ajax: {
          all: {
            action: 'GET /enocloud/service/estimate/rank/all',
            data: 'array',
            loading: true
          },
          work: {
            action: 'GET /enocloud/service/estimate/rank/work',
            data: 'array',
            loading: true
          },
          advisor: {
            action: 'GET /enocloud/service/estimate/rank/advisor',
            data: 'array',
            loading: true
          }
        }
      }
    }
  },

  mounted() {
    this.estimate.get()
  }
})
</script>
